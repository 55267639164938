import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  Legend,
  CartesianGrid,
  Tooltip,
} from "recharts";
import cancel from "../../Images/Cancel.svg";
import InvestmentCard from "./InvestmentCard";
import ViewInvestment from "./ViewInvestments";

function InvestmentList({data}) {
  const [modal, setModal] = useState(false);
  const [isChart, setChart] = useState();
 
  const [modalData, setModalData] = useState()


  const actionFunc=(data)=>{

    console.log('is action', data)
    setModalData(data)
    setModal(true)

  }
  return (
    <>
      <div className=" w-full flex-wrap justify-start items-start gap-6 inline-flex overflow-hidden">
        {data.map((item, id) => (
          <InvestmentCard data={item}  action={actionFunc} />
        ))}
      </div>
      {modal && (
       
        <ViewInvestment closeModal={()=>{ setModal(false)}} data={modalData}/>
      )}
    </>
  );
}

export default InvestmentList;
