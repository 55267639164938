import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../Images/Logo-with-text.svg";
import { ReactComponent as Dashboard } from "../../Images/home.svg";
import { ReactComponent as Withdrawal } from "../../Images/atm-01.svg";
import { ReactComponent as Loan } from "../../Images/money-send-circle.svg";
import { ReactComponent as Settings } from "../../Images/setting.svg";
import { ReactComponent as Support } from "../../Images/suppSide.svg";
import { ReactComponent as Search } from "../../Images/search.svg";
import cancel from "../../Images/Cancel.svg";
import user from "../../Images/userID.png";
import logout from "../../Images/logout.svg";
import burger from "../../Images/HamburgerMenu.svg";
import notification from "../../Images/notifications.svg";
import { useAppSelector } from "../../../utils/redux/store";
import { useAppDispatch } from "../../../utils/redux/store";
import { clearUser } from "../../../utils//redux/authSlice";
import { toast } from "react-toastify";
function SideBar() {
  const [hamburger, setHamburger] = useState(false);
  const location = useLocation();
  const [navBar, setNavBar] = useState(false);
  const { firstName, lastName, email, imageString } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  const menu = [
    {
      id: 1,
      name: "My Lending Portfolio",
      icon: <Dashboard />,
      path: "/lender/dashboard",
    },

    {
      id: 2,
      name: "Loan ",
      icon: <Loan />,
      path: "/lender/loan",
    },
    // {
    //   id: 3,
    //   name: "Withdrawal",
    //   icon: <Withdrawal />,
    //   path: "/lender/withdrawal",
    // },
  ];
  const bottom = [
    {
      id: 1,
      name: "Support",
      icon: <Support />,
      path: "/lender/support",
    },
    {
      id: 2,
      name: "Settings",
      icon: <Settings />,
      path: "/lender/settings",
    },
  ];

  const clearUserData = () => {
    // Remove user data from localStorage
    localStorage.removeItem("ijeDetails");

    dispatch(clearUser());

    toast.success("Logged Out ", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    navigate("/auth/login");
  };
  return (
    <>
      <div className="justify-start overflow-y-scroll 100:hidden lg:flex border-r pt-8 flex-col  border-r-[#CDD1D4] items-start flex fixed top-0 left-0 bg-white w-[320px] h-full">
        <div className=" flex h-full flex-col justify-start items-start w-full gap-6 pl-6 pr-5">
          <div className=" h-11 flex justify-between items-center w-full ">
            <div className=" w-[82.4px] text-[#0A205C]">
              <Logo style={{ color: "#0A205C" }} />
            </div>

            <div></div>
          </div>
          <div className="self-stretch pl-3.5 py-2 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center gap-2 inline-flex text-[#6c757d] text-base font-normal font-['Inter'] leading-normal">
            <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
          </div>
          <div className=" flex flex-col justify-start items-start w-full gap-1">
            {menu.map((item) => (
              <NavLink onClick={() => window.scrollTo(0, 0)} title={item.name} className="w-full" key={item.id} to={item.path}>
                <div className={`h-10 w-full text-[#4a4f54] text-base font-medium px-3 py-2 ${item.path === location.pathname ? "bg-[#e8f7ff]" : "bg-white"} rounded-md justify-start items-center gap-[12px] flex w-full`}>
                  <span>{item.icon}</span>
                  <h3>{item.name}</h3>
                </div>
              </NavLink>
            ))}
          </div>
          <div className=" flex flex-col justify-end h-full pb-[32px] items-end w-full gap-[16px]">
            <div className=" flex flex-col justify-end h-full items-end w-full gap-1">
              {bottom.map((item) => (
                <NavLink onClick={() => window.scrollTo(0, 0)} title={item.name} className="w-full" key={item.id} to={item.path}>
                  <div className={`h-10 w-full text-[#4a4f54] text-base font-medium  px-3 py-2 ${item.path === location.pathname ? "bg-[#e8f7ff]" : "bg-white"}  rounded-md justify-start items-center gap-[12px] flex w-full`}>
                    <span>{item.icon}</span>
                    <h3>{item.name}</h3>
                  </div>
                </NavLink>
              ))}
            </div>
            <div className=" w-full h-px bg-[#cdd1d4]"></div>
            <div className="h-10 w-full px-2 justify-between items-start inline-flex">
              <Link onClick={() => window.scrollTo(0, 0)} className=" w-full" to="/lender/profile">
                <div className="h-10 w-full justify-start items-center gap-3 inline-flex">
                  <img src={imageString ? imageString : user} alt="user" className=" rounded-full object-cover h-10 w-10" />
                  <div className="h-10 flex-col justify-start items-start inline-flex">
                    <h4 className="text-[#3a3c3f] text-sm font-medium  leading-tight">
                      {firstName} {lastName}
                    </h4>
                    <h5 className="text-[#6c757d] text-[12px] font-normal  leading-tight">{email}</h5>
                  </div>
                </div>
              </Link>
              <div onClick={clearUserData} to="/auth/login" className="hover:cursor-pointer">
                <img src={logout} alt="logout" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" fixed p-[20px] w-full   z-40 100:flex lg:hidden flex justify-between items-center">
        <div className={` rounded-[50px] fixed top-[10px]  left-[20px] px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center 100:w-[90%] lg:w-[90%]`}>
          <div className=" w-[50px] text-[#0A205C]">
            <Logo style={{ color: "#0A205C" }} />
          </div>
          <div className=" w-fit justify-start items-center gap-3 inline-flex">
            {/* <img alt="notification" src={notification} /> */}
            <img className=" cursor-pointer" onClick={() => setHamburger(true)} alt="menu" src={burger} />
          </div>
        </div>
      </div>
      {hamburger && (
        <>
          <div className=" inset-[-3] fixed top-0 left-0 w-full h-full z-40 bg-[#344053]/60 backdrop-blur-lg  justify-between items-start inline-flex">
            <div className="justify-start border-r overflow-y-scroll pt-8 flex-col  border-r-[#CDD1D4] items-start flex  bg-white w-[300px] h-full">
              <div className=" flex h-full flex-col justify-start items-start w-full gap-6 pl-6 pr-5">
                <div className=" h-11 flex justify-between items-center w-full ">
                  <div className=" w-[82.4px] text-[#0A205C]">
                    <Logo style={{ color: "#0A205C" }} />
                  </div>
                </div>
                <div className="self-stretch pl-3.5 py-2 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center gap-2 inline-flex text-[#6c757d] text-base font-normal font-['Inter'] leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
                <div className=" flex flex-col justify-start items-start w-full gap-1">
                  {menu.map((item) => (
                    <NavLink onClick={() => window.scrollTo(0, 0)} title={item.name} className="w-full" key={item.id} to={item.path}>
                      <div className={`h-10 w-full text-[#4a4f54] text-base font-medium px-3 py-2 ${item.path === location.pathname ? "bg-[#e8f7ff]" : "bg-white"} rounded-md justify-start items-center gap-[12px] flex w-full`}>
                        <span>{item.icon}</span>
                        <h3>{item.name}</h3>
                      </div>
                    </NavLink>
                  ))}
                </div>
                <div className=" flex flex-col justify-end h-full pb-[32px] items-end w-full gap-[16px]">
                  <div className=" flex flex-col justify-end h-full items-end w-full gap-1">
                    {bottom.map((item) => (
                      <NavLink onClick={() => window.scrollTo(0, 0)} title={item.name} className="w-full" key={item.id} to={item.path}>
                        <div className={`h-10 w-full text-[#4a4f54] text-base font-medium  px-3 py-2 ${item.path === location.pathname ? "bg-[#e8f7ff]" : "bg-white"}  rounded-md justify-start items-center gap-[12px] flex w-full`}>
                          <span>{item.icon}</span>
                          <h3>{item.name}</h3>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                  <div className=" w-full h-px bg-[#cdd1d4]"></div>
                  <div className="h-10 w-full px-2 justify-between items-start inline-flex">
                    <Link className=" w-full" to="/user/profile">
                      <div className="h-10 w-full justify-start items-center gap-3 inline-flex">
                        <img src={imageString ? imageString : user} alt="user" className="rounded-full object-cover h-10 w-10" />
                        <div className="h-10 flex-col justify-start items-start inline-flex">
                          <h4 className="text-[#3a3c3f] text-sm font-medium  leading-tight">
                            {firstName} {lastName}
                          </h4>
                          <h5 className="text-[#6c757d] text-sm font-normal  leading-tight">{email}</h5>
                        </div>
                      </div>
                    </Link>
                    <div onClick={clearUserData} className="hover:cursor-pointer">
                      <img src={logout} alt="logout" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="  pt-[25px] pr-[30px] ">
              <div onClick={() => setHamburger(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default SideBar;
