import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import cancel from "../../Images/Cancel.svg";
import emptyState from "../../Images/emptyState.svg";
import { useAppSelector } from "../../../utils/redux/store";
import CampaignRequest from "../../../utils/services/campaignRequests";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import WalletRequest from "../../../utils/services/walletRequests";
import TextInput from "../../shared/input/TextInput";

import LenderReqeusts from "../../../utils/services/lenderRequests";
import Deposit from "../LoanComponents/deposit";
import InvestmentCard from "../LoanComponents/InvestmentCard";
import { PieChart, Pie, Cell } from "recharts";
import c from "../../Images/calculator.png";
import Select from "react-select";
import LoanCalculator from "../LoanComponents/LoanCalculator";
import ViewInvestment from "../LoanComponents/ViewInvestments";

function Dashboard() {

  const [isData, setData] = useState({});
 
  const [modal, setModal] = useState(false);
  const [calculate, setCalculate] = useState(false);
  const [loanCal, setLoanCal] = useState({});
  const [dashDetails, setDashDetails] = useState({});
  const [isTrans, setTrans] = useState([]);
  const [isInvestment, setInvestment] = useState([]);
  const { firstName } = useAppSelector((state) => state.auth);
  const [modalData, setModalData] = useState()

  const getLenderDashboard = async () => {
    await LenderReqeusts.getLenderDashboard().then((res) => {
      console.log("res", res);
      setDashDetails(res?.data?.result);
    });
  };

  const getLenderDashboardBrief = async () => {
    await LenderReqeusts.getLenderDashboardBrief().then((res) => {
      console.log("res", res);
      setTrans(res?.data?.result?.transactions || []);
      setInvestment(res?.data?.result?.investments || []);
    });
  };
  const calc = (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58333 2.77246L4.58333 6.93913M6.66667 4.85579L2.5 4.85579"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66732 13.6055L5.00065 15.2721M5.00065 15.2721L3.33398 16.9388M5.00065 15.2721L6.66732 16.9388M5.00065 15.2721L3.33398 13.6055"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.667 5.27246L13.3337 5.27246"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6673 15.689L13.334 15.689M16.6673 13.189L13.334 13.189"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.334 10.2725L1.66732 10.2725"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 18.6055L10 1.9388"
        stroke="#4A4F54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  useEffect(() => {
    getLenderDashboard();
    getLenderDashboardBrief();
  }, []);
  const icon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.19998C13.3538 2.0688 12.6849 1.99994 12 1.99994C6.47715 1.99994 2 6.47709 2 11.9999C2 17.5228 6.47715 21.9999 12 21.9999C17.5228 21.9999 22 17.5228 22 11.9999C22 11.315 21.9311 10.6462 21.8 9.99994"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12 8.99994C10.8954 8.99994 10 9.67151 10 10.4999C10 11.3284 10.8954 11.9999 12 11.9999C13.1046 11.9999 14 12.6715 14 13.4999C14 14.3284 13.1046 14.9999 12 14.9999M12 8.99994C12.8708 8.99994 13.6116 9.41734 13.8862 9.99994M12 8.99994V7.99994M12 14.9999C11.1292 14.9999 10.3884 14.5825 10.1138 13.9999M12 14.9999V15.9999"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M22.0699 3.03244C22.3641 2.74091 22.3663 2.26604 22.0748 1.97179C21.7832 1.67754 21.3084 1.67534 21.0141 1.96687L22.0699 3.03244ZM16.4721 6.46691C16.1779 6.75844 16.1757 7.23331 16.4672 7.52756C16.7587 7.82181 17.2336 7.82401 17.5279 7.53248L16.4721 6.46691ZM21.0283 2.22672L20.8975 2.96523L21.0283 2.22672ZM21.7728 2.9714L22.5113 2.84075V2.84075L21.7728 2.9714ZM17.5101 1.25392C17.0959 1.24825 16.7556 1.57941 16.7499 1.99358C16.7442 2.40776 17.0754 2.74811 17.4896 2.75378L17.5101 1.25392ZM21.2457 6.50997C21.2514 6.92415 21.5918 7.25528 22.0059 7.24959C22.4201 7.24389 22.7512 6.90352 22.7455 6.48934L21.2457 6.50997ZM21.0141 1.96687L16.4721 6.46691L17.5279 7.53248L22.0699 3.03244L21.0141 1.96687ZM20.8975 2.96523C20.948 2.97417 20.9755 2.99308 20.9909 3.00852C21.0064 3.02397 21.0253 3.05148 21.0343 3.10206L22.5113 2.84075C22.3872 2.13938 21.8607 1.61246 21.1591 1.48821L20.8975 2.96523ZM21.1591 1.48821C20.1444 1.30851 18.3469 1.26537 17.5101 1.25392L17.4896 2.75378C18.3571 2.76565 20.0255 2.81079 20.8975 2.96523L21.1591 1.48821ZM22.7455 6.48934C22.734 5.65253 22.6908 3.8553 22.5113 2.84075L21.0343 3.10206C21.1885 3.97413 21.2338 5.6425 21.2457 6.50997L22.7455 6.48934Z"
        fill="#0C4AFF"
      />
    </svg>
  );
 
 
  const data = [
    {
      name: "Principal Amount",
      value: loanCal?.principal,
    },
    {
      name: "Interest Rate",
      value: loanCal?.rate,
    },
    {
      name: "Duration",
      value: loanCal?.duration,
    },
  ];
  const COLORS = ["#0C4CFF", "#B3DFFF", "#D5EFFF"];

  const actionFunc = (data) => {
    console.log("is action", data);
    setModalData(data)
    setModal(true);
  };

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] pb-[50px] lg:py-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full flex-col justify-start items-start gap-[8px] inline-flex">
            <h3 className="self-stretch text-[#0c4aff] text-2xl font-medium  leading-[38px]">
              Welcome
              <span className=" font-bold capitalize ">, {firstName}!</span>
            </h3>
            <h3 className="self-stretch  text-[#6c757d] text-base font-normal  leading-normal">
              Total Capital Lent:{" "}
              <span className="text-[#3a3c3f]  font-semibold ">
                {CurrencyFormatter(dashDetails?.capital || 0, "$")}
              </span>{" "}
              | Accrued Interest:{" "}
              <span className="text-[#3a3c3f]  font-semibold ">
                {" "}
                {dashDetails?.interest || 0}%
              </span>
            </h3>
          </div>
          {/* Loan CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              Loading Overview
            </h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] lg:flex-row justify-start items-start">
              <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                  <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">
                    Total Capital Lent
                  </h4>
                  <h3 className="text-[#3a3c3f] text-2xl font-semibold">
                    {CurrencyFormatter(dashDetails?.capital || 0, "$")}
                  </h3>
                  <h5 className="text-[#808990] text-xs font-normal">
                    {dashDetails?.capitalDuration || "------"}
                  </h5>
                </div>
                <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">
                  {icon}
                </div>
              </div>
              <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                  <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">
                    Total Accrued Interest
                  </h4>
                  <h3 className="text-[#3a3c3f] text-2xl font-semibold">
                    {dashDetails?.interest || 0}%
                  </h3>
                  <h5 className="text-[#808990] text-xs font-normal">
                    {" "}
                    {dashDetails?.interestDuration || "------"}
                  </h5>
                </div>
                <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">
                  {icon}
                </div>
              </div>
              <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                  <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">
                    Total Payout at Maturity
                  </h4>
                  <h3 className="text-[#3a3c3f] text-2xl font-semibold">
                    {" "}
                    {CurrencyFormatter(dashDetails?.maturity || 0, "$")}
                  </h3>
                  <div className="h-9 w-full justify-start items-center gap-3 inline-flex">
                    <div className=" h-[8px] items-start flex justify-start bg-[#F1FCF2] rounded-[8px] w-full">
                      <div
                        style={{ width: `${isData?.progress || 0}%` }}
                        className={`h-full  bg-[#28A745] rounded-[8px] `}
                      ></div>
                    </div>
                    <div className=" w-fit flex justify-end items-center">
                      <h5 className="text-[#6C757D] text-[10px] font-normal leading-tight">
                        {isData?.progress || 0}%{" "}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" h-full w-fit gap-y-9 flex justify-between items-center flex-col">
                  <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">
                    {icon}
                  </div>
                  <h5 className="text-[#808990] text-xs font-normal">------</h5>
                </div>
              </div>
            </div>
          </div>
          {/* BUTTON */}
          <div className="h-10 w-full 100:flex-col 100:justify-start 100:items-center flex-1 phone:flex-row phone:justify-end phone:items-center gap-3 inline-flex">
            <div className=" w-full justify-end items-end gap-4 inline-flex">
              <Link className=" w-fit" to="/lender/lend">
                <button className="h-10 text-nowrap w-full text-white text-sm font-medium flex-grow leading-tight px-4 py-2.5 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex">
                  <span className=" 100:hidden sm:flex">Lend Now </span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99996 4.16663V15.8333M4.16663 9.99996H15.8333"
                      stroke="white"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Link>
              <button
                onClick={() => setCalculate(!calculate)}
                className="h-10 w-fit px-3 py-2 bg-[#d5efff] rounded-lg border border-[#aab0b6] justify-center items-center gap-2 inline-flex text-[#4a4f54] text-sm font-medium font-['Inter'] leading-tight"
              >
                <span className=" 100:hidden md:flex">
                  {calculate ? "Cancel" : "Lending Calculator"}
                </span>{" "}
                {calculate ? "" : calc}
              </button>
            </div>
          </div>
          {/* TABLE */}
          <div className=" w-full">
            {/* if null return empty state */}
            {/* <>
              <div className="h-fit w-full py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">No Transactions Yet</h4>
              </div>
            </> */}
            {/* Loan TABLE */}
            <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
              <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                Loan Overview
              </h5>
              <div className="w-full 100:flex-col lg:flex-row justify-start items-start gap-4 inline-flex">
                <div className="h-fit w-full whitespace-nowrap lg:overflow-hidden 100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
                  <div className=" border-t border-t-[#CDD1D4] justify-between items-start flex w-full">
                    <Deposit data={isTrans}  canSearch={true}/>
                  </div>
                </div>
                <div className="w-fit flex-col justify-start items-start gap-4 inline-flex">
                  {isInvestment[0] && (
                    <InvestmentCard
                      data={isInvestment[0]}
                      action={actionFunc}
                    />
                  )}

                  {isInvestment[1] && (
                    <InvestmentCard
                      data={isInvestment[1]}
                      action={actionFunc}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
       <ViewInvestment closeModal={()=>{ setModal(false)}} data={modalData}/>
      )}
      {calculate && <LoanCalculator closeCal={() => setCalculate(false)} />}
    </>
  );
}

export default Dashboard;
