import axios from "axios";
import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();

const LenderReqeusts = {
  createAccount: async (
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    houseAddress,
    createPassword,
    confirmPassword,
    terms,
    bankCode,
    bankAcc,
    photo
  ) => {
    let payload = {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      houseAddress,
      createPassword,
      confirmPassword,
      terms,
      bankCode,
      bankAcc,
      photo,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(process.env.REACT_APP_BASE_URL + "/rest/auth/lender", payload, {
        headers: {},
        method: "POST",
      })
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getLenderDashboard: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/lender/dashboard`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getLenderDashboardBrief: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/lender/dashboard-transactions`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getLenderTransactions: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/lender/transactions`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },


  getLenderInvestments: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/lender/investment`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  calculateLendSimp: async (amount, time) => {
    let payload = {
      amount,
      time,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/lender/investment-calculate",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  initialiseLending: async (amount, currency) => {
    let payload = {
      amount,
      currency,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/lender/investment-initialize",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },


  getInvestmentTransaction: async (ref, ) => {
    let payload = {
      ref,
   
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/lender/investment-transactions",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default LenderReqeusts;
