import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { useState } from "react";
import TextInput from "../../shared/input/TextInput";
import Select from "react-select";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Search } from "../../Images/search.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import confirm from "../../Images/Confirm.svg";
import cancel from "../../Images/Cancel.svg";

function Withdrawals() {
  const [isData, setData] = useState({});
  const [active, setActive] = useState(false);
  const [modal, setModal] = useState(false);
  const arrow = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.91667 4.08301L3.5 10.4997" stroke="#DC3545" stroke-width="1.5" stroke-linecap="round" />
      <path d="M6.4165 3.57672C6.4165 3.57672 9.70274 3.29969 10.2014 3.79839C10.7002 4.29709 10.4231 7.58333 10.4231 7.58333" stroke="#DC3545" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
  const icon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2.19998C13.3538 2.0688 12.6849 1.99994 12 1.99994C6.47715 1.99994 2 6.47709 2 11.9999C2 17.5228 6.47715 21.9999 12 21.9999C17.5228 21.9999 22 17.5228 22 11.9999C22 11.315 21.9311 10.6462 21.8 9.99994"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12 8.99994C10.8954 8.99994 10 9.67151 10 10.4999C10 11.3284 10.8954 11.9999 12 11.9999C13.1046 11.9999 14 12.6715 14 13.4999C14 14.3284 13.1046 14.9999 12 14.9999M12 8.99994C12.8708 8.99994 13.6116 9.41734 13.8862 9.99994M12 8.99994V7.99994M12 14.9999C11.1292 14.9999 10.3884 14.5825 10.1138 13.9999M12 14.9999V15.9999"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M22.0699 3.03244C22.3641 2.74091 22.3663 2.26604 22.0748 1.97179C21.7832 1.67754 21.3084 1.67534 21.0141 1.96687L22.0699 3.03244ZM16.4721 6.46691C16.1779 6.75844 16.1757 7.23331 16.4672 7.52756C16.7587 7.82181 17.2336 7.82401 17.5279 7.53248L16.4721 6.46691ZM21.0283 2.22672L20.8975 2.96523L21.0283 2.22672ZM21.7728 2.9714L22.5113 2.84075V2.84075L21.7728 2.9714ZM17.5101 1.25392C17.0959 1.24825 16.7556 1.57941 16.7499 1.99358C16.7442 2.40776 17.0754 2.74811 17.4896 2.75378L17.5101 1.25392ZM21.2457 6.50997C21.2514 6.92415 21.5918 7.25528 22.0059 7.24959C22.4201 7.24389 22.7512 6.90352 22.7455 6.48934L21.2457 6.50997ZM21.0141 1.96687L16.4721 6.46691L17.5279 7.53248L22.0699 3.03244L21.0141 1.96687ZM20.8975 2.96523C20.948 2.97417 20.9755 2.99308 20.9909 3.00852C21.0064 3.02397 21.0253 3.05148 21.0343 3.10206L22.5113 2.84075C22.3872 2.13938 21.8607 1.61246 21.1591 1.48821L20.8975 2.96523ZM21.1591 1.48821C20.1444 1.30851 18.3469 1.26537 17.5101 1.25392L17.4896 2.75378C18.3571 2.76565 20.0255 2.81079 20.8975 2.96523L21.1591 1.48821ZM22.7455 6.48934C22.734 5.65253 22.6908 3.8553 22.5113 2.84075L21.0343 3.10206C21.1885 3.97413 21.2338 5.6425 21.2457 6.50997L22.7455 6.48934Z"
        fill="#0C4AFF"
      />
    </svg>
  );
  const help = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#6C757D" stroke-width="1.5" />
      <path d="M11.992 15H12.001" stroke="#4A4F54" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 12L12 8" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] pb-[50px] lg:py-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
            <h3 className="self-stretch text-[#3a3c3f] text-2xl font-medium  leading-[34.80px]">Withdrawal</h3>
            <p className="self-stretch text-[#6c757d] text-base font-normal  leading-normal">Easily request a payout from your investments.</p>
          </div>

          <div className=" w-full flex-col justify-start items-center gap-8 inline-flex">
            {/* Loan CARDS */}
            <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
              <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Lending Overview</h5>
              <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] lg:flex-row justify-start items-start">
                <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                  <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">Total Capital Lent</h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold">$0</h3>
                    <h5 className="text-[#808990] text-xs font-normal">------</h5>
                  </div>
                  <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">{icon}</div>
                </div>
                <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                  <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">Total Accrued Interest</h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold">0%</h3>
                    <h5 className="text-[#808990] text-xs font-normal">------</h5>
                  </div>
                  <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">{icon}</div>
                </div>
                <div className="100:h-fit lg:h-[139px] flex-grow lg:w-[328px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start inline-flex">
                  <div className="w-fit flex-col justify-start items-start gap-2 inline-flex">
                    <div className="w-fit flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">Total Payout at Maturity</h4>
                      <h5 className=" text-[#808990] text-xs font-light leading-tight">(based on exchange rate) </h5>
                    </div>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold">₦0</h3>
                  </div>
                  <div className=" h-full w-fit gap-y-9 flex justify-between items-center flex-col">
                    <div className="w-12 h-12 100:hidden md:flex bg-[#d5efff] justify-center items-center rounded-[28px] border-8 border-[#e8f7ff]">{icon}</div>
                    <h5 className="text-[#808990] text-xs font-normal">------</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex 100:flex-col lg:flex-row w-full justify-between items-start gap-6">
            {/* if no loan return empty state */}
            {/* <>
              <div className="h-fit w-full py-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                <img className=" 100:w-[100px] md:w-[150px]" src={emptyState} alt="" />
                <h4 className="text-center text-[#aab0b6] text-xs font-normal leading-normal">No Transactions Yet</h4>
              </div>
            </> */}
            <div className={`h-fit w-full p-8 bg-white rounded-2xl shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] flex-col justify-start items-center gap-8 inline-flex`}>
              <div className="w-full flex-col justify-start items-start gap-6 inline-flex">
                <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
                  <TextInput label={"Amount"} type="text" placeholder="Enter amount to be withdrawn" />
                  {active && (
                    <div className="h-fit w-full px-3 py-2.5 bg-[#f5f6f6] rounded-lg justify-start items-start gap-2.5 inline-flex">
                      <div className="self-stretch text-[#667084] text-sm font-normal  leading-tight">
                        You’ll receive: <span class="text-[#242628] text-sm font-medium  leading-tight">₦75,000</span> (based on today’s exchange rate <span class="text-[#242628] text-sm font-normal  leading-tight">$1900</span>)
                      </div>
                    </div>
                  )}
                </div>
                <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="gender">
                  Reason for withdrawal
                  <Select
                    className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "100%",
                        height: "48px",
                        background: "#fff",
                        outline: "none",
                      }),
                    }}
                    placeholder="Select reason for  withdrawal"
                    // options={currency}
                    // options={isBank || []}
                    // onChange={(e) => {
                    //   formik.setFieldValue("bank", e.value);
                    //   formik.setFieldValue("bank", e.value);
                    // }}
                  />
                </label>
              </div>
              <div className="w-full flex-col justify-center items-end  inline-flex">
                <button
                  onClick={() => setModal(true)}
                  className={`h-10 text-nowrap w-fit text-white text-sm font-medium flex-grow leading-tight px-5 py-3 ${active ? "border border-[#0c4cff] bg-[#0c4cff]" : "bg-[#b3dfff]"} rounded-lg shadow  justify-center items-center flex`}
                >
                  Request Withdrawal
                </button>
              </div>
              <div className="w-full flex-col justify-start items-center gap-6 inline-flex">
                <div className="h-px w-full bg-[#cdd1d4]" />
                <div className="h-fit w-full px-3 py-2.5 bg-[#f5f6f6] gap-4 rounded-lg justify-center items-start  inline-flex">
                  {help}
                  <ul className=" w-fit list-disc text-[#4a4f54] text-xs font-medium leading-tight flex-col justify-start items-start gap-1.5 inline-flex">
                    <li>Withdrawals are processed within 24-48 hours after admin approval.</li>
                    <li>For early withdrawals, interest may be reduced based on the withdrawal date.</li>
                  </ul>
                </div>
              </div>
            </div>
            {active && (
              <>
                <div className="lg:w-[360px] 100:w-full flex-wrap justify-start items-start gap-4 inline-flex">
                  <div className="100:min-w-[300px] flex-1  iphone:min-w-[317px]  flex-col justify-start items-start gap-2 inline-flex">
                    <h4 className="text-[#6c757d] text-sm font-medium  leading-normal">Last withdrawal</h4>
                    <div className="w-full  h-fit p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)]  border border-[#e5e7e8] justify-between items-center  inline-flex ">
                      <div className="w-fit  justify-start items-start gap-2.5 inline-flex">
                        <div className="h-[34px] w-[34px] p-2.5 bg-red-50 rounded-[999px] justify-center items-center inline-flex"> {arrow}</div>
                        <div className="w-fit flex-col justify-end items-start gap-1 inline-flex">
                          <h2 className="self-stretch text-[#3a3c3f] text-lg font-semibold ">$100,000-</h2>
                          <h5 className="grow shrink basis-0 text-[#6c757d] text-[10px] font-medium ">17th May 2024</h5>
                        </div>
                      </div>
                      <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex bg-[#f1fcf2]`}>
                        <Completed />
                        <div className={`text-center text-[#1f7634] text-xs font-medium leading-[18px]`}>Successful</div>
                      </div>
                    </div>
                  </div>{" "}
                  {/* Transactions in progress */}
                  <div className="100:min-w-[300px] flex-1  iphone:min-w-[317px]   h-fit p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)]  border border-[#e5e7e8] flex-col justify-center items-start gap-6  inline-flex ">
                    <div className="justify-between items-center flex w-full">
                      <div className="w-fit  justify-start items-start gap-2.5 inline-flex">
                        <div className="h-[34px] w-[34px] p-2.5 bg-red-50 rounded-[999px] justify-center items-center inline-flex"> {arrow}</div>
                        <div className="w-fit flex-col justify-end items-start gap-1 inline-flex">
                          <h2 className="self-stretch text-[#3a3c3f] text-lg font-semibold ">$100,000-</h2>
                          <h5 className="grow shrink basis-0 text-[#6c757d] text-[10px] font-medium ">17th May 2024</h5>
                        </div>
                      </div>
                      <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex bg-[#ffffea]`}>
                        <Pending />
                        <div className={`text-center text-[#bb6902] text-xs font-medium leading-[18px]`}>Processing</div>
                      </div>
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 inline-flex">
                      <div className="h-px w-full bg-[#cdd1d4]" />
                      <div className="w-full flex-col justify-center items-end  inline-flex">
                        <button className=" text-nowrap w-fit  text-[#6c757d] text-xs font-medium  leading-tight">Cancel Request</button>
                      </div>
                    </div>
                  </div>
                  <div className="h-fit 100:min-w-[300px] flex-1  iphone:min-w-[317px]  p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] flex-col justify-start items-center gap-8 inline-flex overflow-hidden">
                    <div className="w-full flex-col justify-start items-start gap-5 inline-flex">
                      <div className="h-fit px-6 py-1 bg-[#e8f7ff] rounded-[999px] flex-col justify-start items-center gap-2 inline-flex self-stretch text-center text-[#4a4f54] text-base font-medium  leading-normal">Withdrawal Summary</div>
                      <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
                        <div className="w-full justify-between items-start gap-2 inline-flex">
                          <div className="w-fit flex-col justify-start items-start gap-1 inline-flex">
                            <h3 className="self-stretch text-left text-[#242628] text-base font-medium  leading-tight">$500</h3>
                            <h5 className="self-stretch text-left text-[#6c757d] text-xs font-normal  leading-[17.40px]">Requested Amount</h5>
                          </div>
                          <div className="w-fit flex-col justify-end items-end gap-1 inline-flex">
                            <h3 className="self-stretch text-right text-[#242628] text-base font-medium  leading-tight">$1,000</h3>
                            <h5 className="self-stretch text-right text-[#6c757d] text-xs font-normal  leading-[17.40px]">Remaining Balance</h5>
                          </div>
                        </div>
                        <div className="w-fit flex-col justify-end items-end gap-1 inline-flex">
                          <h3 className="self-stretch text-left text-[#242628] text-base font-medium  leading-tight">$50</h3>
                          <h5 className="self-stretch text-left text-[#6c757d] text-xs font-normal  leading-[17.40px]">Dedction Fee</h5>
                        </div>
                      </div>
                      <ul className="h-fit w-full px-6 py-2.5 bg-[#f5f6f6] rounded-lg list-disc text-[#4a4f54] text-xs font-normal leading-tight flex-col justify-center items-center gap-1.5 inline-flex">
                        <li>Withdrawals are processed within 24-48 hours after admin approval.</li>
                        <li>For early withdrawals, interest may be reduced based on the withdrawal date.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Withdrawal Request Submitted!</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Your request has been received. An admin will review and process it shortly.</p>
                    </div>
                  </div>
                  <button onClick={() => setModal(false)} className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                    Back to Home
                  </button>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Withdrawals;
