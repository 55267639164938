import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import TextInput from "../../shared/input/TextInput";
import { useFormik } from "formik";

import cancel from "../../Images/Cancel.svg";

import confirm from "../../Images/Confirm.svg";
import WalletRequest from "../../../utils/services/walletRequests";
import NeedHelp from "../../shared/NeedHelp";
import { currencyData2 } from "../../../utils/helpers/constants";
import Select from "react-select";
import { initializeLendingValidator } from "../../../utils/services/validation";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import { toast } from "react-toastify";
import LenderReqeusts from "../../../utils/services/lenderRequests";

function LendingForm() {
  const [active, setActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [todayRate, setTodayRate] = useState("");

  const getDollarRateToday = async () => {
    await WalletRequest.getCurrentDollarRate().then((res) => {
      setTodayRate(res?.data?.result?.dollarRate || 0);
    });
  };

  useEffect(() => {
    getDollarRateToday();
  }, []);

  const formik = useFormik({
    initialValues: {
      amount: "",
      currency: "NGN",
      terms: false,
    },
    validationSchema: initializeLendingValidator,
    onSubmit: async (values) => {
      console.log("is vales", 100 * parseFloat(todayRate));

      console.log("is her", values.amount);
      if (values.currency === "USD" && parseFloat(values.amount) < 100) {
        toast.error("Minimum amount to lend is $100", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      } else if (
        values.currency === "NGN" &&
        // values.amount < 100 * parseFloat(todayRate)
        values.amount < 100
      ) {
        toast.error(
          `Minimum amount to lend is ${CurrencyFormatter(
            100 ,
            "$"
          )}`,
          {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          }
        );
      } else {

        setLoading(true)
        await LenderReqeusts.initialiseLending(
          parseFloat(values.amount) * todayRate,
          values.currency
        )
          .then((res) => {
            console.log("res", res);

            if (values.currency === "USD") {
              toast.warning("Intiated ", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setModal(true);
            } else {
              toast.warning("Intiated ", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                onClose: () => {
                  window.location.href = res.data.result.authorization_url;
                },
              });

              // console.log(res.result.authorization_url);
              // window.location.href = res.result.authorization_url;

              // window.open(res.result.authorization_url, '_blank');// new tab
            }
          })
          .catch((err) => {

            setLoading(false)
            toast.error(
              `Looks like something went wrong, please try again later`,
              {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              }
            );
          });
      }
    },
  });

  return (
    <>
      <div className=" bg-white flex-col h-screen w-full gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <NeedHelp />
        </div>
        <div className=" 100:w-[85%] pt-[180px] md:w-[480px] gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">
              Lend
            </h4>
            <h2 className=" text-center text-neutral-700 md:text-3xl 100:text-[28px] 100:leading-8 font-semibold  md:leading-10">
              Enter your payment details to securely lend.
            </h2>
          </div>
          <form className="flex-col w-full flex gap-8 justify-start items-start ">
            <div className="w-full flex-col justify-start items-start gap-6 inline-flex">
              <div className="w-full gap-1.5 flex-col justify-start items-start inline-flex">
                {/* <label
                  className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                  htmlFor="currency"
                >
                  Currency
                  <Select
                    className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "100%",
                        height: "48px",
                        background: "#fff",
                        outline: "none",
                      }),
                    }}
                    placeholder="Select Currency"
                    name="currency"
                    options={currencyData2}
                    onChange={(e) => {
                      formik.setFieldValue("currency", e.value);
                    }}
                  />
                </label> */}

                <TextInput
                  label={"USD Amount"}
                  type="text"
                  value={formik.values.amount}
                  onBlur={formik.handleBlur("amount")}
                  onChange={formik.handleChange("amount")}
                  error={formik.touched.amount && formik.errors.amount}
                  placeholder="Enter the Amount to Lend (Minimum $100)"
                />

                {/* {formik.values.currency === "USD" && ( */}
                <TextInput
                  label={"Amount Due"}
                  type="text"
                  readOnly={true}
                  value={CurrencyFormatter(
                    formik.values.amount * todayRate,
                    "₦"
                  )}
                  placeholder=""
                />
                {/* )} */}

                <div className="text-[#808990] text-sm font-normal  leading-tight">
                  The minimum amount to lend is{" "}
                  <span className="text-[#4a4f54] text-sm font-normal leading-tight">
                    $100.
                  </span>
                </div>
              </div>{" "}
              <label
                className=" w-full flex-wrap flex justify-start items-center gap-[6px] leading-[20px] text-[#6C757D] text-[14px] font-normal "
                htmlFor="Terms"
              >
                <input
                  required
                  className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     "
                  type="checkbox"
                  value={formik.values.terms}
                  onChange={formik.handleChange("terms")}
                />
                I agree to the{" "}
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-[#4A4F54] underline"
                  to="/legal/termsofuse"
                >
                  terms
                </Link>{" "}
                and{" "}
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-[#4A4F54] underline"
                  to="/legal/privacypolicy"
                >
                  privacy policy
                </Link>
                of lending.
              </label>
            </div>
          </form>
          <div className=" flex justify-between items-center w-full">
            <Link className=" w-fit" to="/lender/dashboard">
              <button className="h-12  w-fit  px-5 py-3 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] justify-center items-center gap-2 flex text-[#4a4f54] text-base font-medium leading-normal">
                Cancel
              </button>
            </Link>

            <button
              onClick={formik.handleSubmit}
              className={`h-12 px-5 py-3 ${"border-[#0C4AFF] border bg-[#0C4AFF]"} rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]  justify-center items-center gap-2 flex text-white text-base font-medium  leading-normal`}
            >
             {isLoading? "Loading...": "Submit"}
            </button>
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => setModal(false)}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Submitted Successfully
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        Your donation has been submitted successfully please
                        contact support on further details of how to confirm.
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => setModal(false)}
                    className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                  >
                    Back to Home
                  </button>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default LendingForm;
