import { useAppDispatch, useAppSelector } from "../../../utils/redux/store";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import edit from "../../Images/editReg.svg";
import pick from "../../Images/file.svg";
import { useState } from "react";
import { useFormik } from "formik";
import TextInput from "../../shared/input/TextInput";
import { SaveCancelButton } from "./SaveCancelButton";
import { EditButton } from "./EditButton";
import FileInput from "../../shared/input/FileInput";
import {
  countryData,
  durationStudyData,
} from "../../../utils/helpers/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import EducationRequest from "../../../utils/services/educationRequests";
import UserRequest from "../../../utils/services/userRequets";
import { setUser } from "../../../utils/redux/authSlice";
function Education() {
  const { email, acceptanceStatus, educationInformation } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const [uniEdit, setUniEdit] = useState(false);
  const [certEdit, setCertEdit] = useState(false);
  const [transEdit, setTransEdit] = useState(false);
  const [schoolEdit, setSchoolEdit] = useState(false);
  const [studyEdit, setStudyEdit] = useState(false);
  const [countryEdit, setCountryEdit] = useState(false);
  const [studyDurEdit, setStudyDurEdit] = useState(false);
  const [letterEdit, setLetterEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      university: educationInformation?.university,
      certificate: educationInformation?.certificate,
      transcript: educationInformation?.transcript,
      schoolAdmitted: educationInformation?.schoolAdmitted,
      courseOfStudy: educationInformation?.courseOfStudy,
      country: educationInformation?.country,
      durationOfStudy: educationInformation?.durationOfStudy,
      admissionLetter: educationInformation?.admissionLetter,
    },
    onSubmit: async (values) => {
      const payload = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      setLoading(true);
      await EducationRequest.registerEducationedit(payload)
        .then(async (res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            await UserRequest.getUserDetials(email).then((res) => {
              if (res.data.statusCode === 200) {
                localStorage.setItem(
                  "ijeDetails",
                  JSON.stringify(res.data.result)
                );
                dispatch(setUser({ ...res.data.result }));

                toast.success("Information Updated", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });

                setUniEdit(false);
                setTransEdit(false);
                setCertEdit(false);
                setSchoolEdit(false);
                setStudyEdit(false);
                setCountryEdit(false);
                setStudyDurEdit(false);
                setLetterEdit(false);
              } else {
                toast.error(res?.data?.message || res?.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
              }
            });
          } else {
            setLoading(false);

            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }

          // setPage(page + 1)
        })
        .finally(()=>{
          setLoading(false)
        })
    },
  });

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        formik.setFieldValue(name, base64String);
        // console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  University/Polytechnic Attended
                </h4>

                {uniEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.university}
                    onChange={formik.handleChange("university")}
                    onBlur={formik.handleBlur("university")}
                    placeholder="University/Polytechnic Attended"
                    error={
                      formik.touched.university && formik.errors.university
                    }
                  />
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                      {educationInformation?.university}
                    </h3>
                  </>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              uniEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setUniEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setUniEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Certificate
                </h4>
                {certEdit ? (
                  <FileInput
                    label={"Attach Certificate"}
                    error={formik.errors.certificate}
                    onChange={handleFileChange}
                    name="certificate"
                  />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">
                        School Certificate
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              certEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setCertEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setCertEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Transcript
                </h4>

                {transEdit ? (
                  <FileInput
                    label={"Attach Transcript"}
                    error={formik.errors.transcript}
                    onChange={handleFileChange}
                    name="transcript"
                  />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">
                        Transcript
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              transEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setTransEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setTransEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  School Admitted To
                </h4>
                {schoolEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.schoolAdmitted}
                    onChange={formik.handleChange("schoolAdmitted")}
                    onBlur={formik.handleBlur("schoolAdmitted")}
                    placeholder="School Admitted To"
                    error={
                      formik.touched.schoolAdmitted &&
                      formik.errors.schoolAdmitted
                    }
                  />
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                      {educationInformation?.schoolAdmitted}
                    </h3>
                  </>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              schoolEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setSchoolEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setSchoolEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  {" "}
                  Course of Study
                </h4>
                {studyEdit ? (
                  <TextInput
                    label={""}
                    type="text"
                    value={formik.values.courseOfStudy}
                    onChange={formik.handleChange("courseOfStudy")}
                    onBlur={formik.handleBlur("courseOfStudy")}
                    placeholder="Course of Study"
                    error={
                      formik.touched.courseOfStudy &&
                      formik.errors.courseOfStudy
                    }
                  />
                ) : (
                  <>
                    <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                      {educationInformation?.courseOfStudy}
                    </h3>
                  </>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :

              studyEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setStudyEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setStudyEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Country
                </h4>

                {countryEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor="  Country"
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select country"
                      name="country"
                      options={countryData}
                      onChange={(e) => {
                        formik.setFieldValue("country", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.country}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              countryEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setCountryEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setCountryEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Duration of Study
                </h4>
                {studyDurEdit ? (
                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor=""
                  >
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Duration of Study"
                      name="durationOfStudy"
                      options={durationStudyData}
                      onChange={(e) => {
                        formik.setFieldValue("durationOfStudy", e.value);
                      }}
                    />
                  </label>
                ) : (
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {educationInformation?.durationOfStudy}
                  </h3>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              studyDurEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setStudyDurEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setStudyDurEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className=" w-full flex justify-start items-start gap-2.5">
              <div className=" w-full grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Admission Letter
                </h4>
                {letterEdit ? (
                  <FileInput
                    label={""}
                    error={formik.errors.admissionLetter}
                    onChange={handleFileChange}
                    name="admissionLetter"
                  />
                ) : (
                  <div className=" gap-4 w-full justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-[4px] w-full flex-col  justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium font-inter  leading-tight">
                        Admission Letter
                      </h4>
                      <h5 className="text-[#6c757d] text-xs font-normal eading-tight">
                        200 KB
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {acceptanceStatus === "ACCEPT" ? null :
              letterEdit ? (
                <>
                  <SaveCancelButton
                    cancelFunc={() => {
                      setLetterEdit(false);
                    }}
                    loading={isLoading}
                    saveFunc={formik.handleSubmit}
                  />
                </>
              ) : (
                <>
                  <EditButton
                    func={() => {
                      setLetterEdit(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                Status
              </h4>

              {acceptanceStatus === "PENDING" ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Pending /> Pending Approval
                </div>
              ) : educationInformation?.stage > 2 &&
                !educationInformation?.failedPages?.includes(2) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                  {" "}
                  <Completed /> Completed
                </div>
              ) : educationInformation?.failedPages?.includes(2) ? (
                <div className="h-[20px] w-fit  gap-[4px] pl-1.5 pr-2 py-0.5 mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                  <Reject /> Rejected
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Education;
