function ContactUs() {
  const features = [
    {
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 6.16016C22 5.06016 21.1 4.16016 20 4.16016H4C2.9 4.16016 2 5.06016 2 6.16016M22 6.16016V18.1602C22 19.2602 21.1 20.1602 20 20.1602H4C2.9 20.1602 2 19.2602 2 18.1602V6.16016M22 6.16016L12 13.1602L2 6.16016"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Chat to sales",
      description: "Speak to our friendly team.",
      contact: "hello@ijefund.com",
      type: "email",
    },
    {
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 22.1602C17.5228 22.1602 22 17.683 22 12.1602C22 6.63731 17.5228 2.16016 12 2.16016C6.47715 2.16016 2 6.63731 2 12.1602C2 13.539 2.27907 14.8527 2.78382 16.0478C3.06278 16.7083 3.20226 17.0386 3.21953 17.2881C3.2368 17.5377 3.16334 17.8123 3.01642 18.3614L2 22.1602L5.79877 21.1437C6.34788 20.9968 6.62244 20.9234 6.87202 20.9406C7.12161 20.9579 7.45185 21.0974 8.11235 21.3763C9.30745 21.8811 10.6211 22.1602 12 22.1602Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
          <path
            d="M8.58815 12.5375L9.45909 11.4558C9.82616 10.9999 10.2799 10.5754 10.3155 9.96842C10.3244 9.8151 10.2166 9.12672 10.0008 7.75002C9.91601 7.20897 9.41086 7.16016 8.97332 7.16016C8.40314 7.16016 8.11805 7.16016 7.83495 7.28947C7.47714 7.45291 7.10979 7.91246 7.02917 8.29749C6.96539 8.60212 7.01279 8.81203 7.10759 9.23184C7.51023 11.015 8.45481 12.776 9.91948 14.2407C11.3842 15.7053 13.1452 16.6499 14.9283 17.0526C15.3481 17.1474 15.558 17.1948 15.8627 17.131C16.2477 17.0504 16.7072 16.683 16.8707 16.3252C17 16.0421 17 15.757 17 15.1868C17 14.7493 16.9512 14.2441 16.4101 14.1594C15.0334 13.9436 14.3451 13.8357 14.1917 13.8447C13.5847 13.8802 13.1603 14.334 12.7044 14.7011L11.6227 15.572"
            stroke="white"
            stroke-width="1.5"
          />
        </svg>
      ),
      title: "Chat with support",
      description: "We’re here to help.",
      contact: "+2348163153270",
      type: "whatsapp",
    },
    {
      icon: (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.6666 17.0803V20.0803C22.6678 20.3588 22.6107 20.6344 22.4991 20.8896C22.3876 21.1448 22.2239 21.3738 22.0187 21.5621C21.8135 21.7504 21.5712 21.8937 21.3074 21.983C21.0435 22.0722 20.764 22.1053 20.4866 22.0803C17.4095 21.7459 14.4536 20.6944 11.8566 19.0103C9.44045 17.4749 7.39196 15.4264 5.85662 13.0103C4.1666 10.4015 3.11486 7.43125 2.78662 4.34025C2.76163 4.06372 2.7945 3.78501 2.88312 3.52188C2.97175 3.25874 3.11419 3.01694 3.30139 2.81187C3.48858 2.60681 3.71642 2.44296 3.97041 2.33078C4.2244 2.21859 4.49896 2.16051 4.77662 2.16025H7.77662C8.26193 2.15548 8.73241 2.32733 9.10038 2.64379C9.46835 2.96024 9.7087 3.3997 9.77662 3.88025C9.90324 4.84032 10.1381 5.78298 10.4766 6.69025C10.6112 7.04818 10.6403 7.43717 10.5605 7.81113C10.4808 8.1851 10.2955 8.52836 10.0266 8.80025L8.75662 10.0703C10.1802 12.5738 12.2531 14.6467 14.7566 16.0703L16.0266 14.8003C16.2985 14.5314 16.6418 14.3461 17.0157 14.2663C17.3897 14.1866 17.7787 14.2157 18.1366 14.3503C19.0439 14.6888 19.9866 14.9236 20.9466 15.0503C21.4324 15.1188 21.876 15.3635 22.1932 15.7377C22.5103 16.112 22.6788 16.5898 22.6666 17.0803Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Call Us",
      description: "Mon-Fri from 8am to 5pm.",
      contact: "+234 816 315 3270",
      type: "tel",
    },
    {
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5 9.16016C14.5 10.5409 13.3807 11.6602 12 11.6602C10.6193 11.6602 9.5 10.5409 9.5 9.16016C9.5 7.77944 10.6193 6.66016 12 6.66016C13.3807 6.66016 14.5 7.77944 14.5 9.16016Z" stroke="white" stroke-width="2" />
          <path
            d="M13.2574 17.6537C12.9201 17.9786 12.4693 18.1602 12.0002 18.1602C11.531 18.1602 11.0802 17.9786 10.7429 17.6537C7.6543 14.6609 3.51519 11.3177 5.53371 6.46389C6.6251 3.83948 9.24494 2.16016 12.0002 2.16016C14.7554 2.16016 17.3752 3.83948 18.4666 6.46389C20.4826 11.3116 16.3536 14.6713 13.2574 17.6537Z"
            stroke="white"
            stroke-width="2"
          />
          <path d="M18 20.1602C18 21.2647 15.3137 22.1602 12 22.1602C8.68629 22.1602 6 21.2647 6 20.1602" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
      ),
      title: "Visit Us",
      description: "Mon-Fri from 9am to 5pm.",
      contact: "Plot 1B, Block 123, TF Kuboye Road, Off Maruwa Roundabout, Victoria Island. Lagos",
      type: "location",
    },
  ];

  return (
    <>
      <div className=" w-full py-[48px] bg-white  flex justify-center items-center">
        <section className="100:w-[85%] xl:w-[90%] 2xl:w-[1312px] xl:px-0  lg:w-full lg:px-[80px] gap-[64px] flex justify-start items-start  flex-col">
          <div className="100:w-full lg:w-[750px] gap-[20px] text-left flex flex-col items-start justify-start">
            <div className="flex flex-col gap-[12px] items-start justify-start">
              <h2 className=" font-semibold  text-[#0C4AFF] text-[16px] leading-[24px] ">Contact us</h2>

              <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">Get in Touch with Us</h2>
            </div>
            <p className="text-[20px]  leading-[30px] text-[#6C757D] w-full font-normal">We're here to help! Reach out with any questions or concerns, and our team will assist you promptly.</p>
          </div>
          <div className="w-full 100:flex-col lg:flex-row flex justify-center items-start gap-[24px]">
            {features.map((item, id) => (
              <div key={id} className=" w-full flex p-[24px] flex-col  justify-start items-start gap-[64px] rounded-[8px] 100:h-fit xl:min-h-[320px] bg-[#F5F6F6] ">
                <div className=" h-[48px] flex justify-center items-center rounded-[10px] bg-[#2F7AFF] w-[48px]">{item.icon}</div>
                <section className="flex-col justify-start items-start gap-5 inline-flex">
                  <div className=" flex-col justify-start items-start gap-2 flex">
                    <h4 className=" text-blue-800 text-xl font-medium  leading-loose">{item.title}</h4>
                    <p className=" text-blue-600 text-base font-normal  leading-normal">{item.description}</p>
                  </div>
                  {item.type === "email" ? (
                    <a href={`mailto:${item.contact}`}>
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : item.type === "tel" ? (
                    <a href={`tel:${item.contact}`}>
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : item.type === "whatsapp" ? (
                    <a href={`https://wa.me/${item.contact}`} target="_blank" rel="noopener noreferrer">
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : item.type === "location" ? (
                    <a href={`https://www.google.com/maps?q=${encodeURIComponent(item.contact)}`} target="_blank" rel="noopener noreferrer">
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : (
                    <h3 className="text-base text-blue-600 font-medium leading-normal">{item.contact}</h3>
                  )}
                </section>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactUs;
