import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import AuthRequest from "../../utils/services/authRequests";
import SuccessAlert from "../shared/Alerts/success_alert";
import { maskEmail } from "../../utils/helpers/helper";
import { toast } from "react-toastify";
import logo from "../Images/Logo.svg";

function OtpForgotpassword() {
  const [navBar, setNavBar] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [isMail, setMail] = useState("");
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    if (timeLeft === 0) return;

    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timerId); // Cleanup interval on component unmount
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return ` ${minutes < 10 ? " 0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const handleOTPEnter = (value, index) => {
    if (value.length > 1) return;

    const newOTPValues = [...otpValues];
    newOTPValues[index] = value;
    setOtpValues(newOTPValues);

    if (value !== "" && index < 5) {
      // Move focus to the next input field
      otpInputRefs[index + 1]?.current?.focus();
      // if (otpInputRefs[index === 3]) return "gap-5";
    }
  };

  const handleSubmit = async () => {
    setModal(true);
    const otp = otpValues.join("");
    // const mail = localStorage.getItem("emailData");

    // console.log(mail);
    await AuthRequest.confirmForgotOTP(otp, isMail.toLowerCase()).then((res) => {
      if (res.data.statusCode === 200) {
        // setSuccess(true);
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/auth/create-password");
      } else {
        console.log("error");
        setErrorMessage(res.data.message);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        // setSuccess(false);
      }
    });

    // navigate("/auth/login");
  };
  useEffect(() => {
    const otp = otpValues.join("");
    console.log(otp.length);
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otpValues]);

  useEffect(() => {
    const mail = localStorage.getItem("emailData");
    setMail(mail);
    // console.log(mail);
  }, []);

  const resendOtp = async () => {
    await AuthRequest.resendForgotOTP(isMail.toLowerCase()).then((res) => {
      if (res.data.statusCode === 200) {
        // setSuccess(true);
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.log("error");
        setErrorMessage(res.data.message);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        // setSuccess(false);
      }
    });
  };

  const maskedEmail = maskEmail(isMail);

  console.log(errorMessage);
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  return (
    <>
      <div className=" bg-white flex-col h-screen w-full gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div className={` rounded-[50px]   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-[90%] `}>
            <Link to={"/auth/login"}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className=" w-[66px] 100:hidden phone:block"></div>
          </div>
        </div>
        <div className=" 100:w-[90%] md:w-[500px] pt-[180px] gap-[64px] flex-col flex justify-start items-center">
          <section className="flex-col gap-[12px] w-full flex justify-start items-center">
            <h3 className="self-stretch text-center text-blue-600 text-base font-semibold  leading-normal">Forgot Password OTP Verification</h3>
            <p className=" text-center text-neutral-700 md:text-3xl 100:text-[28px] 100:leading-8 font-semibold  md:leading-10">Securely verify your email with a one-time password.</p>
          </section>
          <section className="  100:w-full iphone:w-[350px] flex-col justify-start items-center gap-[32px] flex">
            {success !== null && (
              <div className=" w-full flex justify-center items-center">
                <SuccessAlert success={success} info={success === true ? "Otp Verfied!" : errorMessage} />
              </div>
            )}
            <h5 className="text-center  text-gray-500 gap-2 justify-center items-start 100:flex-wrap flex text-sm font-normal leading-tight">
              Enter the code we sent to
              <span className="text-blue-600">{maskedEmail}</span>
            </h5>

            <div className=" w-full flex-col justify-start items-center gap-6 inline-flex">
              <div className="self-stretch  justify-center items-center gap-5 inline-flex">
                <div className="justify-center items-center gap-2.5 flex">
                  {otpInputRefs.map((ref, index) => (
                    <input
                      onClick={() => setModal(true)}
                      key={index}
                      ref={ref}
                      onChange={(e) => handleOTPEnter(e.target.value, index)}
                      value={otpValues[index]}
                      className=" text-[#56A1FF] text-[28px] font-semibold w-10 outline-blue-600 p-[12px] justify-center items-center flex h-14 bg-white rounded-lg border border-neutral-300"
                      type="password" //yes
                      maxLength="1"
                    />
                  ))}{" "}
                </div>
                {/* <div className="justify-center items-center gap-2.5 flex">
                  <input type="password" className=" text-[#56A1FF] text-[28px] font-semibold w-10 outline-blue-600 p-[12px] justify-center items-center flex h-14 bg-white rounded-lg border border-neutral-300" />
                  <input type="password" className=" text-[#56A1FF] text-[28px] font-semibold w-10 outline-blue-600 p-[12px] justify-center items-center flex h-14 bg-white rounded-lg border border-neutral-300" />
                  <input type="password" className=" text-[#56A1FF] text-[28px] font-semibold w-10 outline-blue-600 p-[12px] justify-center items-center flex h-14 bg-white rounded-lg border border-neutral-300" />
                </div> */}
              </div>
              <section className=" w-full  flex-col justify-center items-center gap-8 flex">
                <h4 className="text-gray-500 text-sm w-full gap-[4px] font-semibold  100:flex-wrap flex justify-center items-start   leading-tight">
                  Time remaining <span className="text-neutral-700 "> {timeLeft > 0 ? formatTime(timeLeft) : "Time's up!"}</span>
                </h4>
                <div onClick={resendOtp} className="   text-[#6C757D] leading-[20px] text-[14px] gap-[4px] items-center 100:flex-wrap flex justify-center font-medium  w-full ">
                  Didn’t receive the email?{" "}
                  <div onClick={resendOtp} className="text-[#0C4AFF] hover:cursor-pointer">
                    Click to resend
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default OtpForgotpassword;
