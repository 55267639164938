import React, { useState } from "react";
import TextInput from "../../shared/input/TextInput";
import FileInput from "../../shared/input/FileInput";
import cancel from "../../Images/Cancel.svg";
import confirm from "../../Images/Confirm.svg";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { loanApplicationValidator } from "../../../utils/services/validation";
import { useAppSelector } from "../../../utils/redux/store";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
import LoanRequest from "../../../utils/services/loanRequests";
import { toast } from "react-toastify";
import Select from "react-select";
import { repaymentYears } from "../../../utils/helpers/constants";

function NewLoan() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modal, setModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: loanApplicationValidator,
    initialValues: {
      // loan_amount: "",
      paymentTerms: "",
      // document: "",
    },

    onSubmit: async (values) => {
      setLoading(true);

      await LoanRequest.applyUser(values.paymentTerms).then((res) => {
        // setModal(true);
        setLoading(false);

        if (res.data.statusCode === 200) {
          toast.success("Successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
        // ;
      });
    },
  });
  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);
        formik.setFieldValue(name, base64String);
        console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const { educationInformation } = useAppSelector((state) => state.auth);
  return (
    <>
      <div className=" flex-col gap-[40px] w-full flex justify-start items-center">
        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          <h3 className="self-stretch text-[#3a3c3f] text-lg font-medium  leading-7">
            Apply for a New Loan
          </h3>
          <p className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
            Complete the form below to apply for a new micro loan.
          </p>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-fit justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">
              Loan Details
            </h4>
            <p className=" w-full text-[#667084] text-sm font-normal leading-tight">
              Specify your loan requirements.
            </p>
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <TextInput
                label={"Loan amount"}
                type="text"
                placeholder={CurrencyFormatter(
                  parseFloat(educationInformation?.totalFundingRequirement || 0),
                  "$"
                )}
                value={CurrencyFormatter(
                  parseFloat(educationInformation?.totalFundingRequirement || 0),
                  "$"
                )}
                onChange={formik.handleChange("loan_amount")}
                error={formik.touched.loan_amount && formik.errors.loan_amount}
                readOnly={true}
              />
              <label
                className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                htmlFor="  Country"
              >
                Repayment Terms
                <Select
                  className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "100%",
                      height: "48px",
                      background: "#fff",
                      outline: "none",
                    }),
                  }}
                  placeholder="Enter repayment duration"
                  name="paymentTerms"
                  options={repaymentYears}
                  onChange={(e) => {
                    formik.setFieldValue("paymentTerms", e.value);
                  }}
                />
              </label>
            </form>
          </div>
        </div>
        <div className=" w-full px-[24px] flex justify-end items-center gap-3">
          <button className=" h-12 w-fit px-5 py-3 bg-white rounded-lg shadow border border-[#aab0b6] justify-center items-center gap-2 flex text-[#4a4f54] text-base font-medium leading-normal">
            Cancel
          </button>
          <button
            onClick={formik.handleSubmit}
            className=" h-12 w-fit px-5 py-3 bg-[#0c4aff] rounded-lg shadow border border-[#b3dfff]  justify-center items-center gap-2 flex text-white text-base font-medium leading-normal"
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => setModal(false)}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-[280px] p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Updated Successfully
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        Thank you for submitting your documents. Your
                        application is being reviewed, and we will contact you
                        shortly.
                      </p>
                    </div>
                  </div>
                  <Link className="w-full" to="">
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      Ok
                    </div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default NewLoan;
