import { Link } from "react-router-dom";
import TextInput from "../../../shared/input/TextInput";
import { useState } from "react";
import cancel from "../../../Images/Cancel.svg";
import confirm from "../../../Images/Confirm.svg";

function AccountSecurity() {
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className=" flex-col gap-[32px] w-full flex justify-start items-center">
        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          <h3 className="self-stretch text-[#3a3c3f] text-lg font-medium  leading-7">Account Security</h3>
          <p className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Change your password and manage security settings.</p>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">Current Password</h4>
            <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter current password</p>
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <div className=" w-full 100:flex-col md:flex-row flex justify-start gap-6 items-start">
                <TextInput label={"Current Password"} type="password" placeholder="********" />
                <TextInput label={"Confirm Password"} type="password" placeholder="********" />
              </div>
            </form>
          </div>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">New Password</h4>
            <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter new password</p>
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <div className="100:flex-col md:flex-row w-full flex justify-start gap-6 items-start">
                <TextInput label={"New Password"} type="password" placeholder="********" />
                <TextInput label={"Confirm New Password"} type="password" placeholder="********" />
              </div>
            </form>
          </div>
        </div>
        <div className=" w-full px-[24px] flex justify-end items-center gap-3">
          <button className=" h-12 w-fit px-5 py-3 bg-white rounded-lg shadow border border-[#aab0b6] justify-center items-center gap-2 flex text-[#4a4f54] text-base font-medium leading-normal">Cancel</button>
          <button onClick={() => setModal(true)} className=" h-12 w-fit px-5 py-3 bg-[#b3dfff] rounded-lg shadow border border-[#b3dfff]  justify-center items-center gap-2 flex text-white text-base font-medium leading-normal">
            Submit
          </button>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-[280px] p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Updated Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Thank you for submitting your documents. Your application is being reviewed, and we will contact you shortly.</p>
                    </div>
                  </div>
                  <Link className="w-full" to="">
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Ok</div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default AccountSecurity;
