import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import Deposit from "../LoanComponents/deposit";
import c from "../../Images/calculator.png";
import TextInput from "../../shared/input/TextInput";
import Select from "react-select";
import { PieChart, Pie, Cell } from "recharts";
import Withdraw from "../LoanComponents/withdraw";
import cancel from "../../Images/Cancel.svg";
import InvestmentList from "../LoanComponents/investmentList";
import { mothsofInvestment } from "../../../utils/helpers/constants";
import { useFormik } from "formik";
import { lenderCalculatorSimp } from "../../../utils/services/validation";
import LenderReqeusts from "../../../utils/services/lenderRequests";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
function Loan() {
  const [isData, setData] = useState({});
  const [options, setOptions] = useState("deposit");
  const [calculate, setCalculate] = useState(false);
  const [active, setActive] = useState(false);
  const [loanCal, setLoanCal] = useState({});
  const [isDeposits, setDeposits] = useState([]);
  const [isWithdrawals, setWithdrawals] = useState([]);
  const [isInvestments, setInvestments] = useState([]);

  const getTransactions = async () => {
    await LenderReqeusts.getLenderTransactions().then((res) => {
      const transactions = res?.data?.result?.transactions || [];

      const deposits = transactions?.filter((transaction) => transaction?.type === "DEPOSIT");
      const withdrawals = transactions?.filter((transaction) => transaction?.type === "WITHDRAWAL");

      setDeposits(deposits);
      setWithdrawals(withdrawals);
    });
  };

  const getInvestments = async () => {
    await LenderReqeusts.getLenderInvestments().then((res) => {
      const transactions = res?.data?.result?.investments || [];

      setInvestments(transactions || []);
    });
  };

  useEffect(() => {
    getTransactions();
    getInvestments();
  }, []);

  const formik = useFormik({
    validationSchema: lenderCalculatorSimp,
    initialValues: {
      amount: "",
      time: "",
    },
    onSubmit: async (values) => {
      await LenderReqeusts.calculateLendSimp(parseFloat(values.amount), parseFloat(values.time)).then((res) => {
        console.log("is restt", res);
        if (res.data.result) {
          setActive(true);
          setLoanCal(res.data.result);
        }
      });
    },
  });

  const filter = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83337 17.5L5.83337 15" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1667 17.5L14.1667 12.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1667 5L14.1667 2.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.83337 7.5L5.83337 2.5" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.83337 15C5.0568 15 4.66852 15 4.36224 14.8731C3.95385 14.704 3.6294 14.3795 3.46024 13.9711C3.33337 13.6649 3.33337 13.2766 3.33337 12.5C3.33337 11.7234 3.33337 11.3351 3.46024 11.0289C3.6294 10.6205 3.95385 10.296 4.36223 10.1269C4.66852 10 5.0568 10 5.83337 10C6.60994 10 6.99823 10 7.30451 10.1269C7.71289 10.296 8.03735 10.6205 8.20651 11.0289C8.33337 11.3351 8.33337 11.7234 8.33337 12.5C8.33337 13.2766 8.33337 13.6649 8.20651 13.9711C8.03735 14.3795 7.71289 14.704 7.30451 14.8731C6.99823 15 6.60994 15 5.83337 15Z"
        stroke="#6C757D"
        stroke-width="1.5"
      />
      <path
        d="M14.1667 10C13.3902 10 13.0019 10 12.6956 9.87313C12.2872 9.70398 11.9628 9.37952 11.7936 8.97114C11.6667 8.66485 11.6667 8.27657 11.6667 7.5C11.6667 6.72343 11.6667 6.33515 11.7936 6.02886C11.9628 5.62048 12.2872 5.29602 12.6956 5.12687C13.0019 5 13.3902 5 14.1667 5C14.9433 5 15.3316 5 15.6379 5.12687C16.0463 5.29602 16.3707 5.62048 16.5399 6.02886C16.6667 6.33515 16.6667 6.72343 16.6667 7.5C16.6667 8.27657 16.6667 8.66485 16.5399 8.97114C16.3707 9.37952 16.0463 9.70398 15.6379 9.87313C15.3316 10 14.9433 10 14.1667 10Z"
        stroke="#6C757D"
        stroke-width="1.5"
      />
    </svg>
  );
  const calc = (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.58333 2.77246L4.58333 6.93913M6.66667 4.85579L2.5 4.85579" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.66732 13.6055L5.00065 15.2721M5.00065 15.2721L3.33398 16.9388M5.00065 15.2721L6.66732 16.9388M5.00065 15.2721L3.33398 13.6055" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.667 5.27246L13.3337 5.27246" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.6673 15.689L13.334 15.689M16.6673 13.189L13.334 13.189" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.334 10.2725L1.66732 10.2725" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 18.6055L10 1.9388" stroke="#4A4F54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
  const handleToggleOptions = (menu) => {
    setOptions(menu);
  };
  const icon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2.19998C13.3538 2.0688 12.6849 1.99994 12 1.99994C6.47715 1.99994 2 6.47709 2 11.9999C2 17.5228 6.47715 21.9999 12 21.9999C17.5228 21.9999 22 17.5228 22 11.9999C22 11.315 21.9311 10.6462 21.8 9.99994"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12 8.99994C10.8954 8.99994 10 9.67151 10 10.4999C10 11.3284 10.8954 11.9999 12 11.9999C13.1046 11.9999 14 12.6715 14 13.4999C14 14.3284 13.1046 14.9999 12 14.9999M12 8.99994C12.8708 8.99994 13.6116 9.41734 13.8862 9.99994M12 8.99994V7.99994M12 14.9999C11.1292 14.9999 10.3884 14.5825 10.1138 13.9999M12 14.9999V15.9999"
        stroke="#0C4AFF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M22.0699 3.03244C22.3641 2.74091 22.3663 2.26604 22.0748 1.97179C21.7832 1.67754 21.3084 1.67534 21.0141 1.96687L22.0699 3.03244ZM16.4721 6.46691C16.1779 6.75844 16.1757 7.23331 16.4672 7.52756C16.7587 7.82181 17.2336 7.82401 17.5279 7.53248L16.4721 6.46691ZM21.0283 2.22672L20.8975 2.96523L21.0283 2.22672ZM21.7728 2.9714L22.5113 2.84075V2.84075L21.7728 2.9714ZM17.5101 1.25392C17.0959 1.24825 16.7556 1.57941 16.7499 1.99358C16.7442 2.40776 17.0754 2.74811 17.4896 2.75378L17.5101 1.25392ZM21.2457 6.50997C21.2514 6.92415 21.5918 7.25528 22.0059 7.24959C22.4201 7.24389 22.7512 6.90352 22.7455 6.48934L21.2457 6.50997ZM21.0141 1.96687L16.4721 6.46691L17.5279 7.53248L22.0699 3.03244L21.0141 1.96687ZM20.8975 2.96523C20.948 2.97417 20.9755 2.99308 20.9909 3.00852C21.0064 3.02397 21.0253 3.05148 21.0343 3.10206L22.5113 2.84075C22.3872 2.13938 21.8607 1.61246 21.1591 1.48821L20.8975 2.96523ZM21.1591 1.48821C20.1444 1.30851 18.3469 1.26537 17.5101 1.25392L17.4896 2.75378C18.3571 2.76565 20.0255 2.81079 20.8975 2.96523L21.1591 1.48821ZM22.7455 6.48934C22.734 5.65253 22.6908 3.8553 22.5113 2.84075L21.0343 3.10206C21.1885 3.97413 21.2338 5.6425 21.2457 6.50997L22.7455 6.48934Z"
        fill="#0C4AFF"
      />
    </svg>
  );
  const data = [
    {
      name: "Principal Amount",
      value: loanCal?.principal,
    },
    {
      name: "Interest Rate",
      value: loanCal?.rate,
    },
    {
      name: "Duration",
      value: loanCal?.duration,
    },
  ];
  const COLORS = ["#0C4CFF", "#B3DFFF", "#D5EFFF"];

  const actionFunc=(data)=>{

    console.log('is action', data)
    // setModal(true)

  }
  return (
    <>
      {" "}
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] pb-[50px] lg:py-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full 100:flex-col phone:flex-row 100:justify-start 100:gap-[24px] 100:items-stretch  phone:justify-between phone:items-center  inline-flex">
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
              <h3 className="self-stretch text-[#3a3c3f] text-2xl font-medium  leading-[34.80px]">Loan</h3>
              <p className="self-stretch text-[#6c757d] text-base font-normal  leading-normal">Track, manage, and grow your loan effortlessly</p>
            </div>
            <Link className=" 100:w-full phone:w-fit" to="/lender/lend">
              <button className="h-10 text-nowrap w-full text-white text-sm font-medium flex-grow leading-tight px-4 py-2.5 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex">
                Lend Now
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99996 4.16663V15.8333M4.16663 9.99996H15.8333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </Link>
          </div>
          <div className=" w-full flex-col justify-start items-center gap-8 inline-flex">
          
            <div className="w-full h-fit flex-col  justify-start items-start gap-6 inline-flex">
              <div className="w-full 100:gap-4 md:gap-0 100:flex-col-reverse lg:flex-row flex  justify-between items-end border-b-[#cdd1d4] border-b-[1px]">
                <div className="md:w-fit 100:w-full 100:overflow-x-scroll md:overflow-hidden  whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                  <button className={`h-[38px] px-[4px] pb-[8px] ${options === "deposit" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleOptions("deposit")}>
                    Deposits
                  </button>
                  {/* <button className={`h-[38px] px-[4px] pb-[8px] ${options === "withdrawals" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleOptions("withdrawals")}>
                    Withdrawals
                  </button> */}
                  <button className={`h-[38px] px-[4px] pb-[8px] ${options === "investment-list" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => handleToggleOptions("investment-list")}>
                    Lending Portfolio
                  </button>
                </div>
                <div className=" justify-start items-start gap-4 inline-flex">
                  <button className="h-9 w-fit  px-3.5 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#aab0b6] justify-center items-center gap-2 flex text-[#6c757d] text-sm font-medium  leading-tight">
                    {filter}
                    <span className=" 100:hidden md:flex">Filter by status</span>{" "}
                  </button>
                  <button
                    onClick={() => setCalculate(!calculate)}
                    className="h-9 w-fit px-3 py-2 bg-[#d5efff] rounded-lg border border-[#aab0b6] justify-center items-center gap-2 inline-flex text-[#4a4f54] text-sm font-medium font-['Inter'] leading-tight"
                  >
                    {calculate ? "" : calc} <span className=" 100:hidden md:flex">{calculate ? "Cancel" : "Lending Calculator"}</span>
                  </button>
                </div>
              </div>
              <div className="flex w-full justify-between items-start gap-6">
                <div className="w-full">
                  {options === "deposit" && <Deposit data={isDeposits}  canSearch={true}/>}
                  {/* {options === "withdraw" && <Withdraw data={isWithdrawals} canSearch={true} />} */}
                  {options === "investment-list" && <InvestmentList data={isInvestments} />}
                </div>
                {/* loan calculator */}
                {calculate && (
                  <div className="w-[400px] 100:hidden lg:flex   h-fit p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] flex-col justify-start items-center gap-8 ">
                    <div className="w-full flex-col justify-start items-start gap-5 inline-flex">
                      <img src={c} alt="" />
                      <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                        <h3 className="self-stretch text-[#242628] text-lg font-medium leading-7">Simulate Your Returns</h3>
                        <p className="self-stretch text-[#6c757d] text-sm font-normal leading-tight">Use the calculator below to estimate your payout based on your lending amount and duration.</p>
                      </div>
                      <div className="flex flex-col gap-4 w-full items-start justify-center ">
                        <TextInput
                          label={"Lending Amount (min. $100)"}
                          type="text"
                          placeholder="Enter the Amount to Lend "
                          value={formik.values.amount}
                          error={formik.touched.amount && formik.errors.amount}
                          onChange={formik.handleChange("amount")}
                        />
                        <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="time">
                          Lending Duration (months)
                          <Select
                            className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "100%",
                                height: "48px",
                                background: "#fff",
                                outline: "none",
                              }),
                            }}
                            placeholder="Select duration"
                            name="duration"
                            options={mothsofInvestment}
                            onChange={(e) => {
                              formik.setFieldValue("time", e.value);
                            }}
                          />
                        </label>{" "}
                        {active && (
                          <div className="h-fit w-full p-4 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex overflow-hidden">
                            <div className="text-[#6c757d] text-sm font-normal fleading-tight">
                              If you lend <span class="text-[#242628] text-sm font-medium leading-tight">{CurrencyFormatter(loanCal?.principal, "$")}</span> for{" "}
                              <span class="text-[#242628] text-sm font-medium leading-tight">{loanCal?.duration || 0} months</span>, your payout will be{" "}
                              <span class="text-[#242628] text-sm font-semibold leading-tight">{CurrencyFormatter(loanCal?.maturityAmount, "$")}</span> in interest.
                            </div>
                            <div className="h-fit w-full p-3 bg-white rounded-lg justify-between items-center inline-flex">
                              {/* <PieChart
                                className=" flex  "
                                width={100}
                                height={100}
                              >
                                <Pie
                                  data={data}
                                  cx={80}
                                  cy={80}
                                  innerRadius={80}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={50}
                                  dataKey="value"
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                                <Pie
                                  data={data}
                                  cx={80}
                                  cy={20}
                                  startAngle={20}
                                  endAngle={0}
                                  innerRadius={20}
                                  outerRadius={20}
                                  fill="#8884d8"
                                  paddingAngle={5}
                                  dataKey="value"
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                              </PieChart> */}
                              <div className=" flex flex-col justify-start items-start gap-[4px] w-full">
                                <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                                  {" "}
                                  <div className="w-2 h-2 bg-[#B3DFFF] rounded-full"></div>
                                  Principal Amount:
                                  <span className="text-[#242628] font-medium "> {CurrencyFormatter(loanCal?.principal, "$")}</span>
                                </div>

                                <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                                  {" "}
                                  <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                                  Interest Rate:
                                  <span className="text-[#242628] font-medium ">{loanCal?.rate || 0}%</span>
                                </div>
                                <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                                  {" "}
                                  <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                                  Duration:
                                  <span className="text-[#242628] font-medium ">{loanCal?.duration || 0} months</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full justify-start items-start gap-3 inline-flex">
                        <button
                          onClick={() => setCalculate(false)}
                          className=" w-full h-10  justify-center items-center flex  px-[18px] py-2.5 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#cfd4dc]  gap-2  text-[#4a4f54] text-sm font-medium  leading-normal"
                        >
                          Cancel
                        </button>
                        {/* <Link
                          className="w-full"
                          to={active ? "/lender/lend" : null}
                        > */}
                        <button
                          onClick={formik.handleSubmit}
                          className={`w-full h-10  justify-center items-center flex  px-[18px] py-2.5  gap-2    ${" bg-[#0c4cff]"} rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d5efff] text-white text-sm font-medium  leading-tight`}
                        >
                          Calculate
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* end calc */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {calculate && (
        <div className="w-full 100:flex lg:hidden h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div onClick={() => setCalculate(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <section className=" w-full h-screen items-center flex justify-center">
            <div className="phone:w-[400px]  100:w-[85%] overflow-y-scroll   h-[85%] p-6 bg-white rounded-lg shadow-[0px_6px_12px_-4px_rgba(16,24,40,0.16)] flex-col justify-start items-center gap-8 ">
              <div className="w-full flex-col justify-start items-start gap-5 inline-flex">
                <img src={c} alt="" />
                <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#242628] text-lg font-medium leading-7">Simulate Your Returns</h3>
                  <p className="self-stretch text-[#6c757d] text-sm font-normal leading-tight">Use the calculator below to estimate your payout based on your lending amount and duration.</p>
                </div>
                <div className="flex flex-col gap-4 w-full items-start justify-center ">
                  <TextInput label={"Lending Amount (min. $100)"} type="text" placeholder="Enter the Amount to Lend " value={formik.values.amount} error={formik.touched.amount && formik.errors.amount} onChange={formik.handleChange("amount")} />
                  <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="time">
                    Lending Duration (months)
                    <Select
                      className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select duration"
                      name="duration"
                      options={mothsofInvestment}
                      onChange={(e) => {
                        formik.setFieldValue("time", e.value);
                      }}
                    />
                  </label>{" "}
                  {active && (
                    <div className="h-fit w-full p-4 bg-[#f5f6f6] rounded-lg flex-col justify-start items-start gap-4 inline-flex overflow-hidden">
                      <div className="text-[#6c757d] text-sm font-normal fleading-tight">
                        If you lend <span class="text-[#242628] text-sm font-medium leading-tight">{CurrencyFormatter(loanCal?.principal, "$")}</span> for{" "}
                        <span class="text-[#242628] text-sm font-medium leading-tight">{loanCal?.duration || 0} months</span>, your payout will be{" "}
                        <span class="text-[#242628] text-sm font-semibold leading-tight">{CurrencyFormatter(loanCal?.maturityAmount, "$")}</span> in interest.
                      </div>
                      <div className="h-fit w-full p-3 bg-white rounded-lg justify-between items-center inline-flex">
                        {/* <PieChart
                                className=" flex  "
                                width={100}
                                height={100}
                              >
                                <Pie
                                  data={data}
                                  cx={80}
                                  cy={80}
                                  innerRadius={80}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={50}
                                  dataKey="value"
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                                <Pie
                                  data={data}
                                  cx={80}
                                  cy={20}
                                  startAngle={20}
                                  endAngle={0}
                                  innerRadius={20}
                                  outerRadius={20}
                                  fill="#8884d8"
                                  paddingAngle={5}
                                  dataKey="value"
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                              </PieChart> */}
                        <div className=" flex flex-col justify-start items-start gap-[4px] w-full">
                          <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                            {" "}
                            <div className="w-2 h-2 bg-[#B3DFFF] rounded-full"></div>
                            Principal Amount:
                            <span className="text-[#242628] font-medium "> {CurrencyFormatter(loanCal?.principal, "$")}</span>
                          </div>

                          <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                            {" "}
                            <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                            Interest Rate:
                            <span className="text-[#242628] font-medium ">{loanCal?.rate || 0}%</span>
                          </div>
                          <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-xs font-normal leading-tight">
                            {" "}
                            <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                            Duration:
                            <span className="text-[#242628] font-medium ">{loanCal?.duration || 0} months</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full justify-start items-start gap-3 inline-flex">
                  <button
                    onClick={() => setCalculate(false)}
                    className=" w-full h-10  justify-center items-center flex  px-[18px] py-2.5 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#cfd4dc]  gap-2  text-[#4a4f54] text-sm font-medium  leading-normal"
                  >
                    Cancel
                  </button>
                  {/* <Link
                          className="w-full"
                          to={active ? "/lender/lend" : null}
                        > */}
                  <button
                    onClick={formik.handleSubmit}
                    className={`w-full h-10  justify-center items-center flex  px-[18px] py-2.5  gap-2    ${" bg-[#0c4cff]"} rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d5efff] text-white text-sm font-medium  leading-tight`}
                  ></button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Loan;
