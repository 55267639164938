import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import { formatDate, CurrencyFormatter } from "../../../utils/helpers/helper";
import { approveUserValidator } from "../../../utils/services/validation";
import TextArea from "../../shared/input/TextArea";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import { rejectionOptions } from "../../../utils/helpers/constants";
import caution from "../../Images/Caution 1.svg";
import alert from "../../Images/alert-triangle.svg";
import cancel from "../../Images/Cancel.svg";
import RejectionModal from "./CloseModal";
import LoanRequest from "../../../utils/services/loanRequests";

function FinancialSummary({ data }) {
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [isLoading, setLoading] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  // const [failedArray, setFailedArray] = useState([]);
  const { email } = useParams();
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    validationSchema: approveUserValidator,
    initialValues: {
      comment: "",
    },
  });
  const declineInit = () => {
    setModal(true);
  };

  const declineRequest = async (data) => {
    // console.log(data);
    setLoading(true);
    await AdminRequest.rejectRequest(email, formik.values.comment, data).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setModal(false);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    });
  };
  const approveRequest = async () => {
    setLoading(true);
    await AdminRequest.approveRequest(email, formik.values.comment).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAccept(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    });
  };





  const conductCredit =async()=>{

    setLoading(true);
    await LoanRequest.creditCheck(email).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsCheck(false);
        setTimeout(() => {
          window.location.reload();
        }, 1200); 

        
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    });
  }
  return (
    <>
      <div className=" items-start justify-start gap-8 w-full flex flex-col">
        <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Currency</h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.educationInformation?.currency ? data?.educationInformation?.currency : "No Data"}</h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Tuition Fee</h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"> {CurrencyFormatter(parseFloat(data?.educationInformation?.tuitionFee || 0), "$")}</h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Living Expenses</h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{CurrencyFormatter(parseFloat(data?.educationInformation?.livingExpenses || 0), "$")}</h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Total Funding Requirement </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{CurrencyFormatter(parseFloat(data?.educationInformation?.totalFundingRequirement || 0), "$")}</h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Amount available</h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"> {CurrencyFormatter(parseFloat(data?.educationInformation?.amountAvailable || 0), "$")}</h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="w-full justify-between items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Credit Score</h4>
                <button onClick={() => setIsCheck(true)} className="self-stretch text-[#0c4aff] text-sm font-medium font-inter leading-tight">
                  Check
                </button>
              </div>
            </div>
          </div>
          {/* {data?.acceptanceStatus !== "ACCEPT" && ( */}
          <>
            <TextArea
              label={"Leave a comment"}
              type="text"
              value={formik.values.comment}
              hint={"Not more than 200 characters"}
              onBlur={formik.handleBlur("comment")}
              onChange={formik.handleChange("comment")}
              error={formik.touched.comment && formik.errors.comment}
            />
          </>
          {/* )} */}
        </div>
        {/* {data?.acceptanceStatus !== "ACCEPT" && ( */}
        <div className=" w-full flex  100:gap-[16px] 100:flex-col-reverse phone:flex-row  justify-end items-end">
          <div onClick={declineInit} to={"/approver/dashboard"} className=" 100:w-full phone:w-fit">
            <button className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex items-center justify-center font-medium h-[48px] px-7  100:w-full phone:w-[128px] ">
              {isLoading ? "Loading..." : "Reject"}
            </button>
          </div>
          <button
            onClick={approveRequest}
            className="leading-[20px] border-[#0c4aff] border-solid border-[1px] rounded-[8px]  bg-[#0c4aff]   text-base text-[#fff] flex items-center justify-center font-medium h-[48px] px-[16px]  100:w-full phone:w-[128px] "
          >
            {isLoading ? "Loading..." : "Approve"}
          </button>
        </div>
        {/* )} */}

        <RejectionModal
          isLoading={isLoading}
          modal={modal}
          rejectFunc={declineRequest}
          toggleModal={() => {
            setModal(!modal);
          }}
        />
      </div>
      {isCheck && (
        <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div
              onClick={() => {
                setIsCheck(false);
              }}
              className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
            >
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
            <div className=" 100:w-full iphone:w-[450px] h-fit  p-[24px] gap-8 bg-white rounded-xl shadow flex-col justify-start items-center flex">
              <div className="h-fit flex-col justify-start items-center gap-5 inline-flex">
                <img src={caution} alt="" />
                <h2 className="self-stretch text-center text-[#0f1728] text-lg font-medium font-inter leading-7">Confirmation</h2>
                <div className="w-full px-3 py-2 bg-[#f5f6f6] rounded-lg justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative bg-[#ffffea] flex  justify-center items-center rounded-[28px]">
                    <img src={alert} alt="" />
                  </div>
                  <p className="text-[#6c757d] text-xs font-medium font-inter leading-tight">
                    Please note that verification will cost <span className="text-[#242628] font-medium  ">₦2000</span> Per check.
                  </p>
                </div>
              </div>
              <div className=" w-full flex justify-center items-center flex-col gap-[12px]">
                <button className="leading-[20px] border-[#0c4aff] border-solid border-[1px] rounded-[8px]  bg-[#0c4aff]   text-base text-[#fff] flex items-center justify-center font-medium h-[48px] px-[16px] w-full  "
                onClick={conductCredit}
                >
                  {isLoading ? "Loading..." : "Proceed"}
                </button>
                <button onClick={() => setIsCheck(false)} className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex items-center justify-center font-medium h-[48px] px-7 w-full ">
                  {isLoading ? "Loading..." : "Canel"}
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default FinancialSummary;
